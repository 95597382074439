<template>
  <div class="taskHangman">
    <!--
        <canvas ref="canvasRef"></canvas>
        <br>
        <button type="button" @click="keySelected('a')">Klikkaa</button>
        -->

    <div class="letterContainer">
      <div v-for="(letter, i) in correctWord" class="letter" :key="i">
        <template v-if="selectedKeys.indexOf(letter) !== -1">
          {{ letter }}
        </template>
      </div>
    </div>

    <div class="lives">
      <p>
        Voit koettaa painaa näppäimiä korkeintaan
        <strong>{{ lives }}</strong> kertaa.
      </p>
    </div>

    <div class="keyContainer noselect">
      <div
        v-for="(key, i) in keys"
        class="key"
        @click="keySelected(key)"
        :class="{ disabled: selectedKeys.indexOf(key) !== -1 }"
        :key="i"
      >
        {{ key }}
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, onMounted } from "vue";
//import HangmanCanvas from './hangman/HangmanCanvas'

export default {
  name: "Hangman",
  props: ["data", "isTest", "taskId"],
  setup() {
    //const canvasRef = ref(null);
    //var hangmanCanvas = null;

    var keys = [
      "q",
      "w",
      "e",
      "r",
      "t",
      "y",
      "u",
      "i",
      "o",
      "p",
      "å",
      "a",
      "s",
      "d",
      "f",
      "g",
      "h",
      "j",
      "k",
      "l",
      "ö",
      "ä",
      "z",
      "x",
      "c",
      "v",
      "b",
      "n",
      "m",
    ];
    var correctWord = "Taitaen";
    correctWord = correctWord.toLowerCase();
    const lives = ref(correctWord.length + 3);
    const selectedKeys = ref([]);
    const gameFinished = ref(false);

    onMounted(() => {
      //hangmanCanvas = new HangmanCanvas(canvasRef.value);
    });

    function keySelected(key) {
      if (gameFinished.value) {
        return;
      }

      selectedKeys.value.push(key);
      lives.value = lives.value - 1;

      if (checkAnswer()) {
        gameFinished.value = true;
        setTimeout(() => {
          alert("Oikein!");
        }, 100);
      } else if (lives.value === 0) {
        gameFinished.value = true;
        setTimeout(() => {
          alert("Väärin!");
        }, 100);
      } /*else if (correctWord.indexOf(key) === -1) {
                hangmanCanvas.next(lives.value);
            }*/
    }

    function checkAnswer() {
      let correctLetters = 0;
      for (let i = 0; i < correctWord.length; i++) {
        if (selectedKeys.value.indexOf(correctWord[i]) !== -1) {
          correctLetters++;
        }
      }
      if (correctLetters === correctWord.length) {
        return true;
      }
      return false;
    }

    return {
      //canvasRef,
      keys,
      keySelected,
      lives: computed(() => lives.value),
      selectedKeys: computed(() => selectedKeys.value),
      correctWord,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@styles/_taitaen-variables.scss";

.lives {
  margin-top: 20px;
  text-align: center;
}

canvas {
  color: var(--theme-color-first);
  border: var(--theme-color-first) dashed 2px;
  padding: 15px;
  background: green;
}

.letter {
  width: 51px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background-image: url($demoUrl+"/demo/tasks/hangman/letter-holder-bg.png");
  background-repeat: no-repeat;
  color: #919191;
  font-size: 2.4em;
  font-weight: bold;
  float: left;
  margin-right: 3px;
  text-transform: uppercase;
}

.letterContainer {
  display: flex;
  justify-content: center;
}

.keyContainer {
  display: flex;
  flex-wrap: wrap;
  max-width: 600px;
  justify-content: center;
  margin: auto;
}

.key {
  background-image: url($demoUrl+"/demo/tasks/hangman/key-bg.png");
  background-repeat: no-repeat;
  width: 53px;
  height: 53px;
  line-height: 53px;
  text-align: center;
  color: #4e4f51;
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
  float: left;
  text-transform: uppercase;

  &.disabled {
    background-image: url($demoUrl+"/demo/tasks/hangman/key-bg-disabled.png");
    background-repeat: no-repeat;
    cursor: default;
    color: #ccc;
  }
}
</style>